import { createActionGroup, props } from '@ngrx/store';
import { PostTypeEnum } from '../../shared/pages/create-post/create-post.model';
import { CryptoPost } from '../../shared/models/crypto-post.model';
import { TradeData } from '../../shared/models/trade.model';
import { TradePostState } from './model';

export const tradePostActions = createActionGroup({
  source: 'TradePost',
  events: {
    setTradePostState: props<{ tradePostState: TradePostState }>(),
    setPostType: props<{ postType: PostTypeEnum }>(),
    setPostData: props<{ postData: CryptoPost }>(),
    setTradeData: props<{ tradeData: Partial<TradeData> }>(),
    setPostTypeAndPostData: props<{ postData: CryptoPost; postType: PostTypeEnum }>(),
  },
});
