import { createReducer, on } from '@ngrx/store';
import { FiltersState } from './filters.types';
import { filtersActions } from './filters.actions';

export const initialStateFiltersState: FiltersState = {
  quantity: 0,
  paymentMethods: [],
};

export const filtersReducer = createReducer(
  initialStateFiltersState,
  on(filtersActions.saveFilters, (state, filters): FiltersState => {
    return {
      ...state,
      ...filters,
    };
  })
);
