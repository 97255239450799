<app-modal
  [id]="modalId"
  [closeOnBgClick]="true"
  [isSecondLayerModal]="true"
  modalBodyClass="modal-xs"
>
  <div class="d-flex flex-column confirmation-container">
    <div class="d-flex align-items-center justify-content-end primary mx-1">
      <i class="bi bi-x-lg pointer pe-1" (click)="closeModal()"></i>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-3">
      <img
        [@bounce]="showIconNimation"
        (@bounce.done)="showIconNimation = false"
        src="/assets/img/icons/alert-info-icon.svg"
        alt="alert icon"
        class="mx-5"
      />
    </div>
    <div class="text text-center mt-3">
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="message mt-1">
        <span>{{ message }}</span>
      </div>
    </div>

    <div class="row footer">
      <div class="col-6 cancel-btn">
        <button class="btn btn-light w-100" (click)="closeModal()">
          <span>{{ cancelButtonText }}</span>
        </button>
      </div>
      <div class="col-6 confirm-btn">
        <button class="btn w-100" [class]="confirmBtnClass" (click)="onConfirm()">
          @if (isLoading) {
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          } @else {
            <span>{{ confirmBtnText }}</span>
          }
        </button>
      </div>
    </div>
  </div>
</app-modal>
