import { createReducer, on } from '@ngrx/store';
import { tradePostActions } from './trade-post.actions';
import { TradePostState } from './model';
import { CryptoPost } from '../../shared/models/crypto-post.model';
import { PostTypeEnum } from '../../shared/pages/create-post/create-post.model';
import { TradeData, TradeFlowStep } from '../../shared/models/trade.model';
const initialState: TradePostState = {
  postType: PostTypeEnum.Buy,
  postData: {} as CryptoPost,
  tradeData: {} as TradeData,
  currentFlowStep: TradeFlowStep.INSERT_VALUES,
};

export const tradePostReducer = createReducer(
  initialState,

  on(tradePostActions.setTradePostState, (state, { tradePostState }) => tradePostState),

  on(tradePostActions.setPostType, (state, { postType }) => ({
    ...state,
    postType: postType,
  })),

  on(tradePostActions.setPostData, (state, { postData }) => ({
    ...state,
    postData: postData,
  })),

  on(tradePostActions.setTradeData, (state, { tradeData }) => ({
    ...state,
    tradeData: {
      ...state.tradeData,
      ...tradeData,
    },
  })),

  on(tradePostActions.setPostTypeAndPostData, (state, { postType, postData }) => ({
    ...initialState,
    postType: postType,
    postData: postData,
  }))
);
