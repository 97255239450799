import { Currency } from '../constants/currency.constants';
import { AcceptPaymentMethod } from './crypto-post.model';

export interface TradeData {
  quantity: number;
  currencyType: Currency;
  paymentMethod: AcceptPaymentMethod;
  walletId: string;
  walletNetwork: string;
  totalToPay: number;
}

export enum TradeFlowStep {
  INSERT_VALUES = 0,
  CHECK_VALUES = 1,
  CONFIRM_OPERATION = 2,
  PROVIDE_FEEDBACK = 3,
}
