import { PaymentMethodSelection } from '../../models/payment-method.model';
import { UserPaymentMethodSelection } from '../../models/user-payment-method.model';

export enum PostTypeEnum {
  Sell = 'sell',
  Buy = 'buy',
}

export interface TimeLimit {
  id: number;
  name: string;
}

export interface OperationPostData {
  quantity: number;
  unitPrice: number;
  timeLimit: TimeLimit;
  paymentMethods: Array<UserPaymentMethodSelection | PaymentMethodSelection>;
}
