import { Routes } from '@angular/router';
import { MainRouterComponent } from './shared/components/organisms/main-router/main-router.component';
import { authGuard } from './shared/guards/auth.guard';
import { RegisterRouterComponent } from './shared/components/organisms/register-router/register-router.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./shared/pages/login/login.component').then((mod) => mod.LoginComponent),
  },
  {
    path: 'register',
    component: RegisterRouterComponent,
    children: [
      {
        path: 'username',
        loadComponent: () =>
          import('./shared/pages/register/username/register-username.component').then(
            (m) => m.RegisterUsernameComponent
          ),
      },
      {
        path: 'password',
        loadComponent: () =>
          import('./shared/pages/register/password/register-password.component').then(
            (m) => m.RegisterPasswordComponent
          ),
      },
      {
        path: 'additional-info',
        loadComponent: () =>
          import('./shared/pages/register/additional/register-additional.component').then(
            (m) => m.RegisterAdditionalComponent
          ),
      },
      {
        path: 'create-phrase',
        loadComponent: () =>
          import(
            './shared/pages/register/create-phrase/register-create-phrase.component'
          ).then((m) => m.RegisterCreatePhraseComponent),
      },
      {
        path: 'verify-phrase',
        loadComponent: () =>
          import(
            './shared/pages/register/verify-phrase/register-verify-phrase.component'
          ).then((m) => m.RegisterVerifyPhraseComponent),
      },
      {
        path: 'welcome',
        loadComponent: () =>
          import('./shared/pages/register/welcome/register-welcome.component').then(
            (m) => m.RegisterWelcomeComponent
          ),
      },
      {
        path: '**',
        redirectTo: 'username',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: MainRouterComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./shared/pages/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
    ],
  },
  {
    path: 'create-post',
    children: [
      {
        path: ':type',
        loadComponent: () =>
          import('./shared/pages/create-post/create-post.component').then(
            (m) => m.CreatePostComponent
          ),
      },
      {
        path: ':type/confirm',
        loadComponent: () =>
          import('./shared/pages/create-post/post-preview/post-preview.component').then(
            (m) => m.PostPreviewComponent
          ),
      },
    ],
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./shared/pages/profile/my-profile/my-profile.component').then(
            (m) => m.MyProfileComponent
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import(
            './shared/pages/profile/third-party-profile/third-party-profile.component'
          ).then((m) => m.ThirdPartyProfileComponent),
      },
    ],
  },
  {
    path: 'chat',
    loadComponent: () =>
      import('./shared/pages/chat/chat.component').then((m) => m.ChatComponent),
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
